import React from 'react';
import { Container, Typography } from '@mui/material';
import RegistrationForm from '../components/formikInputs/RegistrationForm';
import "../App.css";
const Registration: React.FC = () => {
  return (
    <Container className='home-container app-container'>
      
      <RegistrationForm />
    </Container>
  );
};

export default Registration;