// Login.tsx

import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

interface LoginProps {
  isSignUp: boolean;
  setIsSignUp: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
  redirectToApplication?: boolean; // New prop to control redirection
  onLoginSuccess?: () => void; // Callback function to close the login popup
}

const Login: React.FC<LoginProps> = ({ isSignUp, setIsSignUp, setError, redirectToApplication = true, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [localError, setLocalError] = useState('');
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const navigate = useNavigate();

  // Handle user sign-up with email and password
  const handleSignUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      if (redirectToApplication) {
        navigate('/my-application'); // Navigate to "My Application" page after successful sign-up
      }
      if (onLoginSuccess) {
        onLoginSuccess(); // Close the login popup
      }
    } catch (error) {
      console.error('Sign up error:', error);
      setLocalError('Failed to create an account. Please check your details.');
    }
  };

  // Handle user login with email and password
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (redirectToApplication) {
        navigate('/my-application'); // Navigate to "My Application" page after successful login
      }
      if (onLoginSuccess) {
        onLoginSuccess(); // Close the login popup
      }
    } catch (error) {
      setLocalError('Failed to log in. Please check your email and password.');
    }
  };

  // Handle Google Sign-In with Popup
  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      if (redirectToApplication) {
        navigate('/my-application'); // Navigate to "My Application" page after successful Google login
      }
      if (onLoginSuccess) {
        onLoginSuccess(); // Close the login popup
      }
    } catch (error) {
      console.error('Google Sign-In error:', error);
      setLocalError('Failed to log in with Google. Please try again.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', padding: '2rem' }}>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
      />

      {localError && (
        <Box sx={{ backgroundColor: 'white', padding: '1rem', borderRadius: '8px', marginBottom: '2vh' }}>
          <Typography color="error" variant="body2">
            {localError}
          </Typography>
        </Box>
      )}

      {isSignUp ? (
        <Button variant="contained" color="primary" sx={{ color: 'white', borderRadius: '40px' }} onClick={handleSignUp} fullWidth>
          Sign Up
        </Button>
      ) : (
        <Button variant="contained" color="primary" sx={{ color: 'white', borderRadius: '40px' }} onClick={handleLogin} fullWidth>
          Login
        </Button>
      )}

      <Button 
        variant="outlined" 
        onClick={handleGoogleSignIn} 
        fullWidth 
        sx={{ 
          marginTop: '1rem',
          backgroundColor: 'white',
          color: 'secondary',
          borderRadius: '40px',
          '&:hover': {
            backgroundColor: '#000000',
          }
        }}
      >
        Sign In with Google
      </Button>

      <Button
        variant="text"
        onClick={() => setIsSignUp(!isSignUp)}
        fullWidth
        sx={{ color: 'black', borderRadius: '40px' }}
      >
        {isSignUp ? 'Already have an account? Log in' : "Don't have an account? Sign up"}
      </Button>
    </Box>
  );
};

export default Login;
