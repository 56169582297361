import React, { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ref, get } from "firebase/database";
import { database, auth } from "../components/formikInputs/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";


const DashboardData: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the current user is an admin
    const checkAdmin = async (uid: string): Promise<boolean> => {
      try {
        const adminRef = ref(database, `admins/${uid}`);
        const snapshot = await get(adminRef);

        if (snapshot.exists()) {
          console.log("Admin verified for uid:", uid);
          setIsAdmin(true);
          return true;
        } else {
          console.warn("User is not an admin:", uid);
          setIsAdmin(false);
          return false;
        }
      } catch (error) {
        console.error("Error verifying admin status:", error);
        setIsAdmin(false);
        return false;
      }
    };

    // Fetch all registrations data for admins
    const fetchData = async () => {
      try {
        const dataRef = ref(database, "registrations");
        const snapshot = await get(dataRef);

        if (snapshot.exists()) {
          const registrations = snapshot.val();
          console.log("Fetched registrations data:", registrations);

          const dataList = Object.keys(registrations).map((key) => {
            const entry = registrations[key];
            const savedForm = entry.savedForm || {};
            const timestampKeys = Object.keys(entry).filter(
              (subKey) => subKey !== "savedForm"
            );
            const timestampData = timestampKeys.length
              ? entry[timestampKeys[0]]
              : {};

            const status =
              savedForm.submittedAt || timestampData.submittedAt
                ? "Submitted"
                : "Incomplete";

            const submittedAt =
              savedForm.submittedAt || timestampData.submittedAt;
            let tag = "N/A";
            if (submittedAt) {
              const submissionDate = new Date(submittedAt);
              const earlyBirdDate = new Date(
                Date.UTC(2024, 10, 26, 0, 0, 0, 0)
              );
              tag = submissionDate < earlyBirdDate ? "Early Bird" : "General";
            }

            return {
              id: key,
              fullName:
                savedForm.fullName || timestampData.fullName || "N/A",
              email: savedForm.email || timestampData.email || "N/A",
              country: savedForm.country || timestampData.country || "N/A",
              gender: savedForm.gender || timestampData.gender || "N/A",
              dietaryRestrictions:
                savedForm.dietaryRestrictions ||
                timestampData.dietaryRestrictions ||
                "N/A",
              shirtSize:
                savedForm.shirtSize || timestampData.shirtSize || "N/A",
              levelOfStudy:
                savedForm.levelOfStudy || timestampData.levelOfStudy || "N/A",
              university:
                savedForm.university || timestampData.university || "N/A",
              yearOfStudy:
                savedForm.yearOfStudy || timestampData.yearOfStudy || "N/A",
              teamPreference:
                savedForm.teamPreference ||
                timestampData.teamPreference ||
                "N/A",
              status,
              tag,
            };
          });
          setData(dataList);
        } else {
          console.warn("No data found in registrations");
        }
      } catch (error) {
        console.error("Error fetching registrations data:", error);
      }
    };

    // Initialize the dashboard by verifying admin status and fetching data
    const initialize = () => {
      const authListener = onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("User authenticated with uid:", user.uid);
          checkAdmin(user.uid).then((isAdmin) => {
            if (isAdmin) {
              fetchData();
            } else {
              console.warn("Redirecting non-admin user to home page");
              navigate("/");
            }
          });
        } else {
          console.error("No authenticated user found");
          navigate("/login");
        }
      });

      return () => {
        console.log("Cleaning up auth listener");
        authListener(); // Cleanup listener
      };
    };

    initialize();
  }, [navigate]);
  

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          color="primary"
          sx={{fontSize: "12px"}}
          onClick={() => navigate(`/user/${params.row.id}`)}
        >
          View More
        </Button>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => (
        <span style={{ color: params.value === "Submitted" ? "green" : "inherit" }}>
          {params.value}
        </span>
      ),
    },
    { field: "fullName", headerName: "Full Name", flex: 1, minWidth: 200 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "tag", headerName: "Tag", flex: 1, minWidth: 120 },
    { field: "country", headerName: "Country", flex: 1, minWidth: 150 },
    { field: "gender", headerName: "Gender", flex: 1, minWidth: 120 },
    {
      field: "dietaryRestrictions",
      headerName: "Dietary Restrictions",
      flex: 1,
      minWidth: 200,
    },
    { field: "shirtSize", headerName: "Shirt Size", flex: 1, minWidth: 120 },
    {
      field: "levelOfStudy",
      headerName: "Level of Study",
      flex: 1,
      minWidth: 200,
    },
    { field: "university", headerName: "University", flex: 1, minWidth: 200 },
    {
      field: "yearOfStudy",
      headerName: "Year of Study",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "teamPreference",
      headerName: "Team Preference",
      flex: 1,
      minWidth: 200,
    },
    
  ];

  const totalSubmitted = data.filter((item) => item.status === "Submitted").length;
  const totalPending = data.filter((item) => item.status === "Incomplete").length;
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Registrations
      </Typography>
      <Typography className="text-reg font-nm-16">
      You can sort or filter each tab by hovering over the tab names. To view an applicant's complete form, click on "View More." To search for specific information, use the filter option available in the menu by clicking the three dots when you hover over a tab name. All columns are also adjustable in width!
        </Typography>
      {isAdmin ? (
        <>
          <Typography variant="h6" gutterBottom>
            Total: {data.length}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Total Pending: {totalPending}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Total Submitted: {totalSubmitted}
          </Typography>
          <Box sx={{ width: "100%", height: "70vh", overflow: "hidden" }}> {/* Set fixed height and hide overflow */}
            <Box sx={{ width: "80vw", height: "100%", overflow: "auto" }}> {/* Set fixed width and enable scrolling */}
              <DataGrid
                rows={data}
                columns={columns}
                disableRowSelectionOnClick
                sx={{
                 minWidth: "50vw",
                  "& .MuiDataGrid-cell": {
                    whiteSpace: "nowrap", // Prevents wrapping of text
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f5f5f5", // Adds a subtle background to headers
                  },
                  "& .MuiDataGrid-cell:focus": {
                    outline: "none", // Removes focus outline for a cleaner look
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                pageSizeOptions={[5, 10, 20]}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Typography variant="body1" color="error">
          You do not have permission to view this data. Please contact Ayesha to get permission.
        </Typography>
      )}
    </Box>
  );
};

export default DashboardData;
