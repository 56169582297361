import React, { useState, useEffect } from 'react'; 
import { Typography, Box } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, get } from 'firebase/database';
import { database } from '../components/formikInputs/firebaseConfig';
import DashboardLogin from '../components/formikInputs/DashboardLogin';
import DashboardLayout from '../components/DashboardLayout';

const DashboardHome: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    // Handle authentication and admin verification
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const adminRef = ref(database, `admins/${user.uid}`);
          const snapshot = await get(adminRef);

          if (snapshot.exists()) {
            console.log("Admin verified for user:", user.uid);
            setIsAdmin(true);
          } else {
            console.warn("User is not an admin:", user.uid);
            setIsAdmin(false);
          }

          setIsLoggedIn(true);
        } catch (error) {
          console.error("Error verifying admin status:", error);
          setIsLoggedIn(true); // User is logged in but might not be an admin
          setIsAdmin(false);
        }
      } else {
        console.log("User is not logged in");
        setIsLoggedIn(false);
        setIsAdmin(false);
      }
    });

    return () => {
      console.log("Cleaning up auth listener");
      unsubscribe();
    };
  }, []);

  // If user is not logged in, show the login screen
  if (!isLoggedIn) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <DashboardLogin
          isSignUp={false}
          setIsSignUp={() => {}}
          setError={() => {}}
          onLoginSuccess={() => setIsLoggedIn(true)}
        />
      </Box>
    );
  }

  // If user is logged in but not an admin, show the access denied message
  if (!isAdmin) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h5" color="error">
          You do not have permission to access this dashboard.
        </Typography>
      </Box>
    );
  }

  return (
    <DashboardLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '85vh',
          gap: 2,
        }}
      >
        <Typography variant="h3" textAlign="center" gutterBottom>
          Welcome to the SolutionHacks Dashboard!
        </Typography>
        <Typography variant="body1" textAlign="center" sx={{ maxWidth: '600px' }}>
          Use the side navigation to access different sections of the dashboard.
        </Typography>
        <Typography variant="body1" textAlign="center" sx={{ maxWidth: '600px' }}>
          Analytics will be coming soon here!
        </Typography>
        <Typography variant="body1" textAlign="center" sx={{ maxWidth: '600px' }}>
          The Applications tab has a chart with all the applicants so far. Each tab can be sorted or filtered—hover over tab names.
        </Typography>
        <Typography variant="body1" textAlign="center" sx={{ maxWidth: '600px' }}>
          The Resumes tab holds all links to resumes submitted so far.
        </Typography>
        <Typography variant="body1" textAlign="center" sx={{ maxWidth: '600px' }}>
          If you have any suggestions or requests for the dashboard, please let Ayesha know through discord, thank you!
        </Typography>
      </Box>
    </DashboardLayout>
  );
};

export default DashboardHome;
