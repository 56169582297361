// RegistrationForm.tsx
import {
  ref as storageRefI,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage";

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Autocomplete,
  Popper,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ref as databaseRef, set, get, remove } from "firebase/database";
import { database, auth, storage } from "./firebaseConfig";
import { onAuthStateChanged, User } from "firebase/auth";
import Login from "./Login"; // Import your Login component
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

// Import the JSON files for schools and countries
import schools from "./data/schools.json";
import countries from "./data/countries.json";
import codingLanguages from "./data/codingLanguages.json";

const RegistrationForm: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    university: "",
    customUniversity: "",
    country: "",
    yearOfStudy: "",
    levelOfStudy: "",

    skills: [] as string[],
    teamPreference: "",

    shirtSize: "",
    dietaryRestrictions: "",
    emergencyContact: "",
    agreesToTerms: false,
    workshops: [] as string[],
    hackathonsAttended: "",
    caseCompetitionsAttended: "",
    additionalLinks: "",
    socials: "",
    gdgMember: false,
    reasonForAttending: "",
    customDiet: "",
    pastExperience: "",
    sustainableGoals: "",
    ideasForHackathon: "",
    mlhCodeOfConduct: false,
    mlhPrivacyPolicy: false,
    mlhEmails: false,
    resumeURL: "", // Add resumeURL to formData
  });

  const [user, setUser] = useState<User | null>(null);
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const [activeSection, setActiveSection] = useState<string>("");
  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  // Listen for authentication state changes
  useEffect(() => {
    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        if (currentUser) {
          setOpenLogin(false); // Close login modal if user is authenticated
        }
      });
      return unsubscribe;
    }
  }, [auth]);

  useEffect(() => {
    if (user) {
      loadSavedFormData();
    }
  }, [user]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const file = e.target.files?.[0];
    // if (file && file.size <= 10 * 1024 * 1024) {
    //   // Check if file size is less than or equal to 10 MB
    //   setResumeFile(file);
    // } else {
    //   alert("File size should be less than or equal to 10 MB.");
    // }
  };

  const loadSavedFormData = async () => {
    try {
      const savedFormRef = databaseRef(
        database,
        `registrations/${user?.uid}/savedForm`
      );
      const snapshot = await get(savedFormRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setFormData({
          ...data,
          skills: Array.isArray(data.skills) ? data.skills : [],
          workshops: Array.isArray(data.workshops) ? data.workshops : [],
        });
      }
    } catch (error) {
      console.error("Error loading saved form data:", error);
    }
  };

  const saveFormData = async () => {
    if (!user || !auth.currentUser) {
      alert("You need to be logged in to save your progress.");
      return;
    }

    try {
      const savedFormRef = databaseRef(
        database,
        `registrations/${user.uid}/savedForm`
      );
      await set(savedFormRef, {
        ...formData,
        userId: user.uid,
      });
      alert("Form data saved successfully!");
    } catch (error) {
      console.error("Error saving form data:", error);
      alert("Failed to save form data.");
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const selectedValues = typeof value === "string" ? value.split(",") : value;
    if (name === "workshops" && selectedValues.length > 3) {
      return; // Limit workshops selection to 3
    }
    setFormData({
      ...formData,
      [name]: selectedValues,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user) {
      setOpenLogin(true); // Open login modal if user is not authenticated
      return;
    }

    setIsSubmitting(true);
    try {
      //let resumeURL = formData.resumeURL;

      // Upload resume file if it exists
      // if (resumeFile) {
      //   const storageRef = storageRefI(
      //     storage,
      //     `resumes/${user.uid}/${resumeFile.name}`
      //   );
      //   const uploadTask = uploadBytesResumable(storageRef, resumeFile);

      //   await new Promise<void>((resolve, reject) => {
      //     uploadTask.on(
      //       "state_changed",
      //       null,
      //       (error) => {
      //         console.error("Error uploading file:", error);
      //         reject(error);
      //       },
      //       async () => {
      //         resumeURL = await getDownloadURL(uploadTask.snapshot.ref);
      //         resolve();
      //       }
      //     );
      //   });
      // }

      // Ensure user.uid is defined before using it
      if (user.uid) {
        // Use the user's UID and a timestamp as keys
        await set(
          databaseRef(database, `registrations/${user.uid}/${Date.now()}`),
          {
            ...formData,
            submittedAt: new Date().toISOString(),
          }
        );

        // Delete any saved form data after successful submission
        const savedFormRef = databaseRef(
          database,
          `registrations/${user.uid}/savedForm`
        );
        await remove(savedFormRef);

        alert("Registration submitted successfully!");
        navigate("/my-application"); // Redirect to the confirmation page
      } else {
        throw new Error("User UID is undefined");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting your registration.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseLogin = () => {
    setOpenLogin(false); // Function to close the login modal
  };

  const customSelectStyles = {
    "& .Mui-selected": {
      backgroundColor: "darkblue !important",
      color: "white",
    },
    "& .MuiMenuItem-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  const menuProps = {
    PaperProps: {
      style: {
        backgroundColor: "white", // Change this to your desired background color
      },
    },
  };

  const wordCount = formData.reasonForAttending
    .trim()
    .split(/\s+/)
    .filter(Boolean).length;

  const sections = [
    { id: "personal-info", label: "Personal" },
    { id: "education", label: "Education" },
    { id: "experience", label: "Experience" },
    { id: "submit", label: "Submit" },
  ];

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveSection(id);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = sections.map((section) => {
        const element = document.getElementById(section.id);
        return {
          id: section.id,
          offset: element ? element.offsetTop : 0,
        };
      });

      const scrollPosition = window.scrollY + 100; // Adjust based on your header height
      const currentSection = sectionOffsets.reduce((prev, curr) => {
        return scrollPosition >= curr.offset ? curr : prev;
      }, sectionOffsets[0]);

      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const formatPhoneNumber = (value: string) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  return (
    <Container
      maxWidth="lg"
      style={{
        display: "flex",
        flexDirection: isDesktop ? "row" : "column",
        padding: "20px",
      }}
    >
      {isDesktop ? (
        <Box
          sx={{
            width: "20%",
            paddingRight: "20px",
            position: "sticky",
            top: "40vh",
            alignSelf: "flex-start",
          }}
        >
          <List component="nav">
            {sections.map((section) => (
              <Button
                key={section.id}
                onClick={() => scrollToSection(section.id)}
                sx={{
                  fontWeight: activeSection === section.id ? "bold" : "normal",
                  color: "#000000",
                  textAlign: "left",
                }}
              >
                {section.label}
              </Button>
            ))}
          </List>
        </Box>
      ) : (
        <Box
          sx={{
            position: "sticky",
            top: 70,
            zIndex: 1000,
            backgroundColor: "#f5f5dc",
            display: "flex",
            justifyContent: "center",
            width: "auto",
            padding: "5px 0",
            borderRadius: "30px",
          }}
        >
          {sections.map((section) => (
            <Button
              key={section.id}
              onClick={() => scrollToSection(section.id)}
              sx={{
                margin: "0 5px",
                fontWeight: activeSection === section.id ? "bold" : "normal",
                color: "#0659B5",
              }}
            >
              {section.label}
            </Button>
          ))}
        </Box>
      )}
      <Box sx={{ width: isDesktop ? "80%" : "100%" }}>
        <form onSubmit={handleSubmit}>
          <Typography
            variant="h2"
            color="secondary.contrastText"
            sx={{ marginBottom: "2vh", marginTop: "10vh" }}
          >
            SolutionHacks Registration
          </Typography>
          <Typography
            className="text-reg font-nm-16"
            sx={{ marginBottom: "2vh" }}
          >
            Welcome to SolutionHacks! We're thrilled to have you here. Please
            take your time to fill out the registration form below. At the beginning and end
            of the page, you’ll find a "Save and Continue Later" option that
            lets you save your progress if you need to complete the form at
            another time.
          </Typography>
          <Button
              type="button"
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ marginTop: "10px", borderRadius: "40px" , color: 'white', marginBottom: '5vh'}}
              onClick={saveFormData}
            >
              Save and Continue Later
            </Button>
          {/* Personal Information Section */}
          <div className="regForm">
            <Typography
              variant="h3"
              gutterBottom
              id="personal-info"
              sx={{ marginTop: "5vh" }}
            >
              Personal Information
            </Typography>
            {/* Full Name */}
            <TextField
              id="fullName"
              label="Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              autoComplete="name"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Email Address */}
            <TextField
              id="email"
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              autoComplete="email"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Phone Number */}
            <TextField
              id="phone"
              label="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={(e) => {
                const formattedPhone = formatPhoneNumber(e.target.value);
                handleChange({
                  target: {
                    name: "phone",
                    value: formattedPhone,
                    type: "text",
                    checked: false,
                  } as EventTarget & HTMLInputElement,
                } as React.ChangeEvent<HTMLInputElement>);
              }}
              fullWidth
              required
              margin="normal"
              autoComplete="tel"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Date of Birth */}
            <TextField
              id="dob"
              label="Date of Birth"
              name="dob"
              type="text"
              value={formData.dob}
              onChange={(e) => {
                const formattedDate = e.target.value.replace(
                  /^(\d{2})(\d{2})(\d{4})$/,
                  "$1/$2/$3"
                );
                handleChange({
                  target: {
                    name: "dob",
                    value: formattedDate,
                    type: "text",
                    checked: false,
                  } as EventTarget & HTMLInputElement,
                } as React.ChangeEvent<HTMLInputElement>);
              }}
              fullWidth
              required
              margin="normal"
              placeholder="MM/DD/YYYY"
              autoComplete="bday"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Gender Selection */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  // For default state
                  padding: "0 4px", // Adding padding to avoid overlap
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    // When the label is shrunk
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0", // Ensure this matches the background color for consistency
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                id="gender"
                name="gender"
                value={formData.gender}
                required
                onChange={handleSelectChange}
                sx={customSelectStyles}
                autoComplete="sex"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            {/* Country Selection */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  // For default state
                  padding: "0 4px", // Adding padding to avoid overlap
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    // When the label is shrunk
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0", // Ensure this matches the background color for consistency
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleSelectChange}
                required
                sx={customSelectStyles}
                autoComplete="country"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: "auto",
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  },
                }}
              >
                {countries.map((country) => (
                  <MenuItem key={country.alpha2} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Shirt Size Selection */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="shirtSize-label">Shirt Size</InputLabel>
              <Select
                labelId="shirtSize-label"
                id="shirtSize"
                name="shirtSize"
                value={formData.shirtSize}
                onChange={handleSelectChange}
                required
                sx={customSelectStyles}
                autoComplete="off"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
              >
                <MenuItem value="XS">XS</MenuItem>
                <MenuItem value="S">S</MenuItem>
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="L">L</MenuItem>
                <MenuItem value="XL">XL</MenuItem>
                <MenuItem value="XXL">XXL</MenuItem>
              </Select>
            </FormControl>

            {/* Dietary Restrictions */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="dietaryRestrictions-label">
                Dietary Restrictions
              </InputLabel>
              <Select
                labelId="dietaryRestrictions-label"
                id="dietaryRestrictions"
                name="dietaryRestrictions"
                value={formData.dietaryRestrictions}
                onChange={handleSelectChange}
                required
                sx={customSelectStyles}
                autoComplete="off"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
              >
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                <MenuItem value="Vegan">Vegan</MenuItem>
                <MenuItem value="Gluten-Free">Gluten-Free</MenuItem>
                <MenuItem value="Halal">Halal</MenuItem>
                <MenuItem value="Kosher">Kosher</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="customDiet"
              label="Other Dietary restrictions"
              name="customDiet"
              value={formData.customDiet}
              onChange={handleChange}
              fullWidth
              margin="normal"
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Emergency Contact */}
            <TextField
              id="emergencyContact"
              label="Emergency Contact Phone Number"
              name="emergencyContact"
              value={formData.emergencyContact}
              onChange={(e) => {
                const formattedPhone = formatPhoneNumber(e.target.value);
                handleChange({
                  target: {
                    name: "emergencyContact",
                    value: formattedPhone,
                    type: "text",
                    checked: false,
                  } as EventTarget & HTMLInputElement,
                } as React.ChangeEvent<HTMLInputElement>);
              }}
              fullWidth
              required
              margin="normal"
              autoComplete="tel"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            <Typography
              variant="h3"
              gutterBottom
              id="education"
              sx={{ marginTop: "5vh" }}
            >
              Education
            </Typography>
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="levelOfStudy-label">Level of Study</InputLabel>

              <Select
                labelId="levelOfStudy-label"
                id="levelOfStudy"
                name="levelOfStudy"
                value={formData.levelOfStudy}
                onChange={handleSelectChange}
                required
                sx={customSelectStyles}
                autoComplete="off"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
              >
                <MenuItem value="Less than Secondary / High School">
                  Less than Secondary / High School
                </MenuItem>
                <MenuItem value="Secondary / High School">
                  Secondary / High School
                </MenuItem>
                <MenuItem value="Undergraduate University (2 year - community college or similar)">
                  Undergraduate University (2 year - community college or
                  similar)
                </MenuItem>
                <MenuItem value="Undergraduate University (3+ year)">
                  Undergraduate University (3+ year)
                </MenuItem>
                <MenuItem value="Graduate University (Masters, Professional, Doctoral, etc)">
                  Graduate University (Masters, Professional, Doctoral, etc)
                </MenuItem>
                <MenuItem value="Code School / Bootcamp">
                  Code School / Bootcamp
                </MenuItem>
                <MenuItem value="Other Vocational / Trade Program or Apprenticeship">
                  Other Vocational / Trade Program or Apprenticeship
                </MenuItem>
                <MenuItem value="Post Doctorate">Post Doctorate</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                <MenuItem value="I’m not currently a student">
                  I’m not currently a student
                </MenuItem>
              </Select>
            </FormControl>

            {/* Year of Study Selection */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="yearOfStudy-label">Year of Study</InputLabel>
              <Select
                labelId="yearOfStudy-label"
                id="yearOfStudy"
                name="yearOfStudy"
                value={formData.yearOfStudy}
                onChange={handleSelectChange}
                required
                sx={customSelectStyles}
                autoComplete="off"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
              >
                <MenuItem value="1">Freshman</MenuItem>
                <MenuItem value="2">Sophomore</MenuItem>
                <MenuItem value="3">Junior</MenuItem>
                <MenuItem value="4">
                  Senior (e.g. Grade 12, 4th year or higher, final year of
                  program)
                </MenuItem>
                <MenuItem value="5">Not Applicable</MenuItem>
              </Select>
            </FormControl>

            {/* University/College Selection */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <Autocomplete
                id="university"
                options={schools}
                getOptionLabel={(option) => option}
                value={formData.university}
                onChange={(event, newValue) => {
                  setFormData({
                    ...formData,
                    university: newValue || "",
                  });
                }}
                PopperComponent={(props) => (
                  <Popper {...props} placement="bottom-start" />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="University/College"
                    required
                    sx={{
                      "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#f5f5dc" },
                    }}
                  />
                )}
              />
            </FormControl>

            {/* Custom University Name */}

            <TextField
              id="customUniversity"
              label="If you did not find your University/College in the list above, please enter here"
              name="customUniversity"
              value={formData.customUniversity}
              onChange={handleChange}
              fullWidth
              margin="normal"
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Experience Section */}
            <Typography
              variant="h3"
              gutterBottom
              id="experience"
              sx={{ marginTop: "5vh" }}
            >
              Experience
            </Typography>
            {/* Coding Languages & Skills */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="skills-label">
                Coding Languages & Skills (Select All that Apply)
              </InputLabel>
              <Select
                labelId="skills-label"
                id="skills"
                name="skills"
                multiple
                value={formData.skills}
                onChange={handleMultiSelectChange}
                required
                sx={customSelectStyles}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
                autoComplete="off"
                
                renderValue={(selected) => selected.join(", ")}
              >
                {codingLanguages.map((language) => (
                  <MenuItem
                    key={language}
                    value={language}
                    selected={formData.skills.includes(language)}
                    sx={{
                      backgroundColor: formData.skills.includes(language)
                        ? "darkblue"
                        : "inherit",
                      color: formData.skills.includes(language)
                        ? "black"
                        : "inherit",
                    }}
                  >
                    {language}
                    {formData.skills.includes(language) && <CheckIcon />}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Workshops/Talks Interested In */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="workshops-label">
                Workshops/Talks Interested In (Select up to 3)
              </InputLabel>
              <Select
                labelId="workshops-label"
                id="workshops"
                name="workshops"
                multiple
                value={formData.workshops}
                onChange={handleMultiSelectChange}
                required
                sx={customSelectStyles}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
                autoComplete="off"
                renderValue={(selected) => selected.join(", ")}
              >
                {[
                  "Artificial Intelligence & Machine Learning",
                  "Web Development",
                  "Web3 (Blockchain) Development",
                  "Mobile (iOS, Android) Development",
                  "Game development",
                  "Cybersecurity & Cryptography",
                  "Entrepreneurship",
                  "Startup Development",
                  "Project/Program Management",
                  "Data Science & Data Mining",
                  "Cloud & DevOps",
                  "AR/VR & Computer Vision",
                  "IoT/Hardware",
                  "How to use APIs",
                  "UX/UI",
                ].map((workshop) => (
                  <MenuItem
                    key={workshop}
                    value={workshop}
                    selected={formData.workshops.includes(workshop)}
                    sx={{
                      backgroundColor: formData.workshops.includes(workshop)
                        ? "darkblue"
                        : "inherit",
                      color: formData.workshops.includes(workshop)
                        ? "black"
                        : "inherit",
                    }}
                  >
                    {workshop}
                    {formData.workshops.includes(workshop) && <CheckIcon />}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Number of Hackathons Attended */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="hackathonsAttended-label">
                How many hackathons have you attended?
              </InputLabel>
              <Select
                labelId="hackathonsAttended-label"
                id="hackathonsAttended"
                name="hackathonsAttended"
                value={formData.hackathonsAttended}
                onChange={handleSelectChange}
                required
                sx={customSelectStyles}
                autoComplete="off"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5+">5+</MenuItem>
              </Select>
            </FormControl>

            {/* Number of Case Competitions Attended */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="caseCompetitionsAttended-label">
                How many case competitions have you attended?
              </InputLabel>
              <Select
                labelId="caseCompetitionsAttended-label"
                id="caseCompetitionsAttended"
                name="caseCompetitionsAttended"
                value={formData.caseCompetitionsAttended}
                onChange={handleSelectChange}
                required
                sx={customSelectStyles}
                autoComplete="off"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5+">5+</MenuItem>
              </Select>
            </FormControl>

            {/* Team Preference */}
            <FormControl
              fullWidth
              margin="normal"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
                "& .MuiInputLabel-root": {
                  padding: "0 4px",
                  transform: "translate(14px, 12px) scale(1)",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -6px) scale(0.75)",
                    backgroundColor: "#78AFF0",
                    padding: "0 4px",
                    borderRadius: "5px",
                  },
                },
              }}
            >
              <InputLabel id="teamPreference-label">
                Do you have a team? (this does not affect your application)
              </InputLabel>
              <Select
                labelId="teamPreference-label"
                id="teamPreference"
                name="teamPreference"
                value={formData.teamPreference}
                onChange={handleSelectChange}
                required
                sx={customSelectStyles}
                autoComplete="off"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflow: 'auto',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                }}
              >
                <MenuItem value="Solo">I am working solo</MenuItem>
                <MenuItem value="Looking for teammates">
                  I am looking for teammates
                </MenuItem>
                <MenuItem value="Already have team">
                  I already have a team
                </MenuItem>
              </Select>
            </FormControl>

            {/* Reason for Attending */}
            <Typography
              className="text-reg font-nm-16"
              gutterBottom
              sx={{ marginTop: "2vh", textAlign: "left" }}
            >
              Why do you want to attend SolutionHacks?
            </Typography>
            <TextField
              id="reasonForAttending"
              name="reasonForAttending"
              label="200 words max"
              value={formData.reasonForAttending}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              multiline
              rows={4}
              helperText={`${wordCount}/200 words`}
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Past Experience */}
            <Typography
              className="text-reg font-nm-16"
              gutterBottom
              sx={{ marginTop: "2vh", textAlign: "left" }}
            >
              Tell us about a past experience that you are passionate about. How
              do you hope to apply this to your project at SolutionHacks? (max
              200 words)
            </Typography>
            <TextField
              id="pastExperience"
              label="200 words max"
              name="pastExperience"
              value={formData.pastExperience}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              multiline
              rows={4}
              helperText={`${
                formData.pastExperience.trim().split(/\s+/).filter(Boolean)
                  .length
              }/200 words`}
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Sustainable Development Goals */}
            <Typography
              className="text-reg font-nm-16"
              gutterBottom
              sx={{ marginTop: "2vh", textAlign: "left" }}
            >
              Which of the 17 United Nations Sustainable Development Goals are
              you most interested in? (max 200 words)
              <a
                href="https://sdgs.un.org/goals"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Learn more
              </a>
            </Typography>
            <TextField
              id="sustainableGoals"
              label="200 words max"
              name="sustainableGoals"
              value={formData.sustainableGoals}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              multiline
              rows={4}
              helperText={`${
                formData.sustainableGoals.trim().split(/\s+/).filter(Boolean)
                  .length
              }/200 words`}
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* Steps to get the link from Google Drive */}
            <Typography
              className="text-reg font-nm-16"
              gutterBottom
              sx={{ marginTop: "2vh", textAlign: "left" }}
            >
              <span
                style={{ fontWeight: "boldest", textDecoration: "underline" }}
              >
                STEPS TO GET YOUR RESUME LINK FROM GOOGLE DRIVE:
              </span>
              <ol>
                <li>Upload your resume to Google Drive.</li>
                <li>Rename filename to your <span style={{backgroundColor: '#f5f5dc'}}>firstname_lastname</span>.</li>
                <li>Right-click on the file and select "Get link".</li>
                <li>
                  Ensure the link is set to "Anyone with the link can view".
                </li>
                <li>Copy the link and paste it in the box below.</li>
              </ol>
            </Typography>

            {/* TextField for Resume URL */}
            <TextField
              id="resumeURL"
              label="Resume URL"
              name="resumeURL"
              value={formData.resumeURL}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            {/* <Typography variant="h3" gutterBottom sx={{ marginTop: "5vh" }}>
            Upload Resume
          </Typography>
          <Button
            variant="contained"
            component="label"
            color="primary"
            fullWidth
            sx={{ marginTop: "10px", borderRadius: "40px" }}
          >
            Upload Resume (PDF, DOC, DOCX)
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
              hidden
            />
          </Button>
          {resumeFile && (
            <Typography variant="body2" sx={{ marginTop: "10px" }}>
              Selected file: {resumeFile.name}
            </Typography>
          )} */}

            {/* Submission Section */}
            <Typography
              variant="h3"
              gutterBottom
              id="submit"
              sx={{ marginTop: "5vh" }}
            >
              Submit
            </Typography>

            {/* Socials */}
            <TextField
              id="socials"
              label="Link your socials here (LinkedIn, Instagram)"
              name="socials"
              value={formData.socials}
              onChange={handleChange}
              fullWidth
              margin="normal"
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />
            {/* Additional Links */}
            <TextField
              id="additionalLinks"
              label="Any additional links you would like to share (GitHub, Devpost, Portfolio)?"
              name="additionalLinks"
              value={formData.additionalLinks}
              onChange={handleChange}
              fullWidth
              margin="normal"
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />

            <Typography
              className="text-reg font-nm-16"
              gutterBottom
              sx={{ marginTop: "2vh", textAlign: "left" }}
            >
              What do you hope to see at SolutionHacks? (max 200 words)
            </Typography>
            <TextField
              id="ideasForHackathon"
              label="200 words max"
              name="ideasForHackathon"
              value={formData.ideasForHackathon}
              onChange={handleChange}
              fullWidth
              required
              margin="normal"
              multiline
              rows={4}
              helperText={`${
                formData.ideasForHackathon.trim().split(/\s+/).filter(Boolean)
                  .length
              }/200 words`}
              autoComplete="off"
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#f5f5dc" },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  { borderColor: "#f5f5dc" },
              }}
            />
            <div style={{ textAlign: "left", marginBottom: "5vh" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="gdgMember"
                    checked={formData.gdgMember}
                    onChange={handleChange}
                    required
                    sx={{
                      color: "black",
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                }
                label={
                  <span>
                    Have you signed up as a general GDG TMU member?{" "}
                    <a
                      href="https://gdg.community.dev/gdg-on-campus-toronto-metropolitan-university-toronto-canada/#c6dbSdWXGHW"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#f5f5dc" }}
                    >
                      Sign up here
                    </a>
                  </span>
                }
              />
              <Box mb={2} />
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreesToTerms"
                    checked={formData.agreesToTerms}
                    onChange={handleChange}
                    required
                    sx={{
                      color: "black",
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                }
                label="I agree to the terms and conditions"
              />
              <Box mb={2} />
              <FormControlLabel
                control={
                  <Checkbox
                    name="mlhCodeOfConduct"
                    checked={formData.mlhCodeOfConduct}
                    onChange={handleChange}
                    required
                    sx={{
                      color: "black",
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                }
                label={
                  <span style={{ textAlign: "left" }}>
                    I have read and agree to the{" "}
                    <a
                      href="https://github.com/MLH/mlh-policies/blob/main/code-of-conduct.md"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#f5f5dc" }}
                    >
                      MLH Code of Conduct
                    </a>
                    .
                  </span>
                }
              />
              <Box mb={2} />
              <FormControlLabel
                control={
                  <Checkbox
                    name="mlhPrivacyPolicy"
                    checked={formData.mlhPrivacyPolicy}
                    onChange={handleChange}
                    required
                    sx={{
                      color: "black",
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                }
                label={
                  <span style={{ textAlign: "left" }}>
                    I authorize you to share my application/registration
                    information with Major League Hacking for event
                    administration, ranking, and MLH administration in-line with
                    the{" "}
                    <a
                      href="https://github.com/MLH/mlh-policies/blob/main/privacy-policy.md"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#f5f5dc" }}
                    >
                      MLH Privacy Policy
                    </a>
                    . I further agree to the terms of both the{" "}
                    <a
                      href="https://github.com/MLH/mlh-policies/blob/main/contest-terms.md"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#f5f5dc" }}
                    >
                      MLH Contest Terms and Conditions
                    </a>{" "}
                    and the{" "}
                    <a
                      href="https://github.com/MLH/mlh-policies/blob/main/privacy-policy.md"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#f5f5dc" }}
                    >
                      MLH Privacy Policy
                    </a>
                    .
                  </span>
                }
              />
              <Box mb={2} />
              <FormControlLabel
                control={
                  <Checkbox
                    name="mlhEmails"
                    checked={formData.mlhEmails}
                    onChange={handleChange}
                    sx={{
                      color: "black",
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                }
                label={
                  <span style={{ textAlign: "left" }}>
                    I authorize MLH to send me occasional emails about relevant
                    events, career opportunities, and community announcements.
                    (Optional)
                  </span>
                }
              />
            </div>

            {/* Save and Continue Later Button */}
            <Button
              type="button"
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ marginTop: "10px", borderRadius: "40px" , color: 'white'}}
              onClick={saveFormData}
            >
              Save and Continue Later
            </Button>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="success"
              fullWidth
              sx={{ marginTop: "20px", borderRadius: "40px", color: "white" }}
              disabled={true} // Temporarily disable the submit button
            >
              {isSubmitting ? "Submitting..." : "Submit Registration"}
            </Button>
          </div>
        </form>

        {/* Login Popup */}
        <Dialog open={openLogin} onClose={handleCloseLogin}>
          <DialogTitle>Login to Submit the Form</DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "8px",
            }}
          >
            <Login
              isSignUp={false}
              setIsSignUp={() => {}}
              setError={() => {}}
            />{" "}
            {/* Display the Login component in the popup */}
          </DialogContent>
        </Dialog>
      </Box>
    </Container>
  );
};

export default RegistrationForm;
