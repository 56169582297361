import React, { useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import Login from '../components/formikInputs/Login';

const LoginPage: React.FC = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');

  return (
    <Container className='home-container'>
      <Typography variant="h2" color="secondary.contrastText" sx={{ marginBottom: '2vh' }}>
        {isSignUp ? 'Sign Up' : 'Login'}
      </Typography>
      {error && (
        <Box sx={{ backgroundColor: 'white', padding: '1rem', borderRadius: '8px', marginBottom: '2vh' }}>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </Box>
      )}
      <Login isSignUp={isSignUp} setIsSignUp={setIsSignUp} setError={setError} />
    </Container>
  );
};

export default LoginPage;
