import React, { useState, useEffect } from 'react';
import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { database, auth } from './firebaseConfig';
import { ref, set, get, query } from "firebase/database";
import { onAuthStateChanged, User } from "firebase/auth";
import Login from './Login'; // Ensure this import is correct
import { useNavigate } from 'react-router-dom';

const ContactFormAEB: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('yes');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [openLogin, setOpenLogin] = useState(false); // State to manage the login modal
  const [showBrowserWarning, setShowBrowserWarning] = useState(false); // State to show warning if in Instagram browser
  const [formSubmitted, setFormSubmitted] = useState(false); // State to show thank you message
  const [alreadySubmitted, setAlreadySubmitted] = useState(false); // State to check if form is already submitted
  const navigate = useNavigate();

  // Detect Instagram browser
  useEffect(() => {
    const ua = navigator.userAgent;
    if (ua.includes("Instagram")) {
      setShowBrowserWarning(true);
    }
  }, []);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const emailQuery = query(ref(database, `interestAEB/${currentUser.uid}`));
        const emailSnapshot = await get(emailQuery);
        if (emailSnapshot.exists()) {
          setAlreadySubmitted(true);
        }
      }
    });
    return unsubscribe;
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!user) {
      setOpenLogin(true); // Open login modal if user is not authenticated
      return;
    }

    handleSubmitForm(user);
  };

  const handleSubmitForm = async (currentUser: User) => {
    setIsSubmitting(true);
    const formData = {
      name,
      email,
      contact,
      submittedAt: new Date().toISOString(),
      userId: currentUser.uid,
    };

    try {
      await set(ref(database, `interestAEB/${currentUser.uid}`), formData);
      setFormSubmitted(true); // Show thank you message
      setName('');
      setEmail('');
      setContact('yes');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
      setOpenLogin(false); // Close the login modal
    }
  };

  const handleCloseLogin = () => {
    setOpenLogin(false); // Function to close the login modal
  };

  const handleOpenInBrowser = () => {
    const url = window.location.href;
    const newUrl = url.replace('instagram', 'http'); // Replace 'instagram' with 'http' to open in default browser
    window.open(newUrl, '_blank');
  };

  return (
    <Container sx={{ marginTop: '5vh', padding: '5vw', borderRadius: '30px', backgroundColor: '#f5f5dc' }}>
      {showBrowserWarning && (
        <Box sx={{ backgroundColor: 'white', padding: '1rem', borderRadius: '8px', marginBottom: '2vh' }}>
          <Typography variant="body1" color="error">
            To sign in with Google, please open this page in an external browser.
          </Typography>
        </Box>
      )}
      
      {alreadySubmitted ? (
        <Typography variant="h4" color="success.main" sx={{ marginBottom: '2vh' }}>
          You have already submitted the interest form.
        </Typography>
      ) : formSubmitted ? (
        <>
          <Typography variant="h4" color="success.main" sx={{ marginBottom: '2vh' }}>
            Thank you for your submission!
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ borderRadius: '40px', color: "#fff" }} 
            onClick={() => navigate('/my-application')}
          >
            Go to My Application
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h2" color="secondary.contrastText" sx={{ marginBottom: '2vh' }}>
            Interest Form
          </Typography>
          <Typography variant="h4" color="success.main" sx={{ marginBottom: '2vh' }}>
           Please sign in to submit interest form!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: '2vh' }}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ 
                backgroundColor: '#fff', 
                borderRadius: '30px', 
                '& .MuiOutlinedInput-root': {
                  borderRadius: '30px',
                }
              }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ 
                backgroundColor: '#fff', 
                borderRadius: '30px', 
                '& .MuiOutlinedInput-root': {
                  borderRadius: '30px',
                }
              }}
            />
            <FormControl component="fieldset" sx={{ display: 'flex', alignItems: 'center', marginTop: '5vh' }}>
              <FormLabel component="legend" sx={{ color: '#333' }}>I agree to be contacted when the official SolutionHacks application has opened.</FormLabel>
              <RadioGroup row aria-label="contact" name="contact" value={contact} onChange={(e) => setContact(e.target.value)}>
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormControl>
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: '1vh' }}>
              This form is not an application for SolutionHacks; it is an initial form to help us gauge interest. We will contact you again when applications have officially opened.
            </Typography>
            <Button 
              type="submit" 
              variant="contained" 
              color="success" 
              sx={{ borderRadius: '40px', color: "#fff" }} 
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Box>
        </>
      )}

      {/* Login Popup */}
      <Dialog open={openLogin} onClose={handleCloseLogin}>
        <DialogTitle>Login to Submit the Form</DialogTitle>
        <DialogContent>
          <Login 
            isSignUp={false} 
            setIsSignUp={() => {}} 
            setError={() => {}} 
            redirectToApplication={false} // Prevent redirection after login
            onLoginSuccess={handleCloseLogin} // Close the login popup after successful login
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ContactFormAEB;