import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { database } from '../components/formikInputs/firebaseConfig';
import { Box, Button, Container, Typography, Divider } from '@mui/material';

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userRef = ref(database, `registrations/${id}`);
      try {
        const snapshot = await get(userRef);
        let tag = 'N/A';
  
        if (snapshot.exists()) {
          const userData = snapshot.val();
          let extractedData: { submittedAt?: string } = {};
  
          // Extract submittedAt from savedForm or timestamp key
          if (userData.savedForm) {
            extractedData = userData.savedForm;
          } else {
            const timestampKey = Object.keys(userData).find((key) => /^\d+$/.test(key));
            if (timestampKey) {
              extractedData = userData[timestampKey];
            }
          }
  
          const submittedAt = extractedData.submittedAt;
  
          if (submittedAt) {
            const submissionDate = new Date(submittedAt); // Parse the UTC timestamp
            const earlyBirdCutoff = new Date(Date.UTC(2024, 10, 26, 0, 0, 0)); // November 26, 2024, 00:00:00 UTC
  
            // Compare directly as both dates are in UTC
            tag = submissionDate <= earlyBirdCutoff ? 'Early Bird' : 'General';
            console.log("Submitted At:", submittedAt);
console.log("Submission Date (Parsed):", submissionDate);
console.log("Early Bird Cutoff:", earlyBirdCutoff);
console.log("Tag Assigned:", tag);

          }
  
          setUser({ ...extractedData, id, tag, submittedAt });
        } else {
          console.error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
  
    fetchUser();
  }, [id]);
  
  

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 3 }}>
        <Typography variant="h3" gutterBottom>
          {user ? `${user.fullName}'s Application` : 'User Details'}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/data')}
        >
          Back to Dashboard
        </Button>
      </Box>
      {user ? (
        <>
          {/* Wrapper for all sections */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              marginTop: 3,
            }}
          >
            {/* Personal Information Section */}
            <Box
              sx={{
                padding: 3,
                backgroundColor: '#f9f9f9',
                borderRadius: 2,
                boxShadow: 2,
                display: 'flex',
                flexDirection: 'column',
                gap:2,
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                Personal Information
              </Typography>
              <Divider />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>ID:</strong> {user.id}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Full Name:</strong> {user.fullName || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Email:</strong> {user.email || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Phone:</strong> {user.phone || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Date of Birth:</strong> {user.dob || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Gender:</strong> {user.gender || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Country:</strong> {user.country || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Shirt Size:</strong> {user.shirtSize || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Dietary Restrictions:</strong> {user.dietaryRestrictions || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Emergency Contact:</strong> {user.emergencyContact || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Status:</strong> {user.submittedAt ? 'Submitted' : 'In Progress'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Tag:</strong> {user.tag}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Submitted At:</strong> {user.submittedAt || 'N/A'}</Typography>
                </Box>
              </Box>
            </Box>

            {/* Education Section */}
            <Box
              sx={{
                padding: 3,
                backgroundColor: '#f9f9f9',
                borderRadius: 2,
                boxShadow: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                Education
              </Typography>
              <Divider />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>University:</strong> {user.university || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Custom University:</strong> {user.customUniversity || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Year of Study:</strong> {user.yearOfStudy || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Level of Study:</strong> {user.levelOfStudy || 'N/A'}</Typography>
                </Box>
              </Box>
            </Box>

            {/* Experience Section */}
            <Box
              sx={{
                padding: 3,
                backgroundColor: '#f9f9f9',
                borderRadius: 2,
                boxShadow: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                Experience
              </Typography>
              <Divider />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Skills:</strong> {user.skills?.join(', ') || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Workshops:</strong> {user.workshops?.join(', ') || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Hackathons Attended:</strong> {user.hackathonsAttended || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Case Competitions Attended:</strong> {user.caseCompetitionsAttended || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Team Preference:</strong> {user.teamPreference || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Resume URL:</strong> {user.resumeURL ? <a href={user.resumeURL} target="_blank" rel="noopener noreferrer">{user.resumeURL}</a> : 'N/A'}</Typography>
                </Box>
              </Box>
            </Box>
            {/* Long Answers Section */}
            <Box
              sx={{
                padding: 3,
                backgroundColor: '#f9f9f9',
                borderRadius: 2,
                boxShadow: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                Long Answers
              </Typography>
              <Divider />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Box sx={{ flex: '1 1 100%' }}>
                  <Typography><strong>Why do you want to attend SolutionHacks?:</strong> {user.reasonForAttending || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 100%' }}>
                  <Typography><strong>Tell us about a past experience that you are passionate about. How
              do you hope to apply this to your project at SolutionHacks?: </strong> {user.pastExperience || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 100%' }}>
                  <Typography><strong>Which of the 17 United Nations Sustainable Development Goals are
                  you most interested in?:</strong> {user.sustainableGoals || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 100%' }}>
                  <Typography><strong>What do you hope to see at SolutionHacks?:</strong> {user.ideasForHackathon || 'N/A'}</Typography>
                </Box>
              </Box>
            </Box>
         
            {/* Additional Information Section */}
            <Box
              sx={{
                padding: 3,
                backgroundColor: '#f9f9f9',
                borderRadius: 2,
                boxShadow: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                Additional Information
              </Typography>
              <Divider />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
               
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Socials:</strong> {user.socials || 'N/A'}</Typography>
                </Box>
                <Box sx={{ flex: '1 1 45%' }}>
                  <Typography><strong>Additional Links:</strong> {user.additionalLinks || 'N/A'}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/data')}
            sx={{ marginTop: 3 }}
          >
            Back to Dashboard
          </Button>
        </>
      ) : (
        <Typography variant="body1">Loading user details...</Typography>
      )}
    </Container>
  );
};

export default UserDetails;
