import React from 'react';
import './Loader.css';

const Loader: React.FC = () => {
  return (
    <div className="loader-container">
      <img  width='300' src={`${process.env.PUBLIC_URL}/logo_green.png`} alt="Logo" className="spinning-logo" />
    </div>
  );
};

export default Loader;