import React from 'react';
import { Box, Drawer, List, ListItem, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const drawerWidth = 120;

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {/* Sidebar Drawer */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Vertically center
            alignItems: 'center', // Horizontally center
            height: '100%',
          }}
        >
          <ListItem sx={{ width: '100%', textAlign: 'center' }}>
            <Button component={Link} to="/" fullWidth>
              Home
            </Button>
          </ListItem>
          <ListItem sx={{ width: '100%', textAlign: 'center' }}>
            <Button component={Link} to="/data" fullWidth>
              Applications
            </Button>
          </ListItem>
          <ListItem sx={{ width: '100%', textAlign: 'center' }}>
            <Button component={Link} to="/resumes" fullWidth>
              Resumes
            </Button>
          </ListItem>
          {/* Add more navigation items as needed */}
        </List>
      </Drawer>

      {/* Main Content */}
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        {children} {/* Render only the content specific to the current route */}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
