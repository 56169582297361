import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { SwipeableDrawer, List, ListItem, ListItemButton, ListItemText, Link as ALink } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { getAuth, signOut, onAuthStateChanged, User } from 'firebase/auth';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const pages = [
  {
    title: 'Home',
    url: '/'
  },
  {
    title: 'About',
    url: '/#about'
  },
   {
     title: 'Application',
     url: '/#application'
   },
  // {
  //   title: 'Sponsor',
  //   url: '/#sponsor'
  // },
  {
    title: 'FAQs',
    url: '/#faq'
  }
];

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [showBg, setShowBg] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = React.useState<User | null>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, [auth]);

  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
    handleCloseUserMenu();
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleHomeClick = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  useScrollPosition(({ currPos }) => {
    setShowBg(currPos.y < 0);
  });

  return (
    <AppBar position="fixed" elevation={showBg ? 1 : 0} sx={{
      background: showBg ? '#78AFF0' : 'none',
      boxShadow: showBg ? '0 4px 8px rgba(0,0,0,0.1)' : 'none'
    }}>
      <Container maxWidth="lg">  
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            <Link to='/'>
              <img 
                width={90}
                height={60}
                src={`${process.env.PUBLIC_URL}/text logo 1_white.svg`}
                alt="GDG logo"
              />
            </Link>
          </Box>
          <Typography
  variant="h6"
  noWrap
  component={Link}
  to="/"
  sx={{
    height: 40,
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center', // Center vertically
    justifyContent: 'center' // Center horizontally (optional)
  }}
>
  <img 
    height={150}
    src={`${process.env.PUBLIC_URL}/text logo 1_white.png`} 
    alt="GDG logo"
    style={{ display: 'block' }} // Ensure the image is displayed as a block element
  />
</Typography>
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              height: 23.9,
              display: { xs: 'flex', flexGrow: 1, justifyContent: 'center', md: 'none' }
            }}
          >
          </Typography>
          
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            {pages.map((page) => (
              <Button
                key={page.title}
                component={page.title === 'Home' ? 'button' : HashLink}
                to={page.url}
                smooth
                onClick={page.title === 'Home' ? handleHomeClick : undefined}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                {page.title}
              </Button>
            ))}
            {user ? (
              <Box sx={{ flexGrow: 0, ml: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', mx: 1 }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', ml: 2, mr: 2 }}>
                  <Avatar alt={user.displayName || 'Profile'} src={user.photoURL || ''} />
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => { navigate('/my-application'); handleCloseUserMenu(); }}>My Application</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button component={Link} to="/login" sx={{ my: 2, color: 'black', display: 'block' }}>
                Login
              </Button>
            )}
          </Box>

          <Box sx={{ display: { xs: 'inline-block', md: 'none' }, marginLeft: 'auto' }}>
            <IconButton
              size="large"
              aria-label="side menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              onOpen={handleOpenNavMenu}
              anchor={'right'} // Change the drawer anchor to the right
              open={!!anchorElNav}
              PaperProps={{
                sx: {
                  backgroundColor: '#ffffff',
                  width: '100%' // Make the drawer full screen
                }
              }}
              onClose={handleCloseNavMenu}
            >
              <Box
                sx={{ 
                  width: '100%', // Make the drawer full screen
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100vh' // Ensure it takes the full height
                }}
                role="presentation"
                onClick={handleCloseNavMenu}
                onKeyDown={handleCloseNavMenu}
              >
                <Box
  sx={{
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    paddingTop: '24px',
    paddingLeft: '16px',
    paddingRight: '16px', // Add padding to the right
    maxWidth: '100%', // Ensure it takes the full width
    position: 'relative' // Set position to relative for the Box
  }}
>
  <img 
    width={150} // Increased width
    height='auto' // Increased height
    src={'/logo_green.png'} 
    alt="GDSC logo"
  />
  <Close 
    onClick={handleCloseNavMenu} 
    sx={{ 
      color: 'black', 
      position: 'absolute', // Position absolute for the Close icon
      right: '16px' // Align to the right with padding
    }} 
  />
</Box>
                <List sx={{ width: '100%' }}>
                  {pages.map((text, index) => (
                    <ListItem key={text.title} disablePadding sx={{ justifyContent: 'center' }}>
                      <ListItemButton component={text.title === 'Home' ? 'button' : HashLink} to={text.url} smooth onClick={text.title === 'Home' ? handleHomeClick : undefined} sx={{ textAlign: 'center', padding: '16px 0' }}>
                        <ListItemText primary={text.title} sx={{ color: 'black', '& .MuiTypography-root': { fontSize: { xs: '2.5rem', sm: '2rem' } }, textAlign: 'center' }}/>
                      </ListItemButton>
                    </ListItem>
                  ))}
                  {user ? (
                    <>
                      <ListItem disablePadding sx={{ justifyContent: 'center' }}>
                        <ListItemButton component={Link} to="/my-application" sx={{ textAlign: 'center', padding: '16px 0' }}>
                          <ListItemText primary="My Application" sx={{ color: 'black', '& .MuiTypography-root': { fontSize: { xs: '2.5rem', sm: '2rem' } }, textAlign: 'center' }}/>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding sx={{ justifyContent: 'center' }}>
                        <ListItemButton onClick={handleLogout} sx={{ textAlign: 'center', padding: '16px 0' }}>
                          <ListItemText primary="Logout" sx={{ color: 'black', '& .MuiTypography-root': { fontSize: { xs: '2.5rem', sm: '2rem' } }, textAlign: 'center' }}/>
                        </ListItemButton>
                      </ListItem>
                    </>
                  ) : (
                    <ListItem disablePadding sx={{ justifyContent: 'center' }}>
                      <ListItemButton component={Link} to="/login" sx={{ textAlign: 'center', padding: '16px 0' }}>
                        <ListItemText primary="Login" sx={{ color: 'black', '& .MuiTypography-root': { fontSize: { xs: '2.5rem', sm: '2rem' } }, textAlign: 'center' }}/>
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </Box>
            </SwipeableDrawer>
          </Box>
          <Button color='secondary' className="bttnF" component={Link} to="/my-application" variant="contained" sx={{display: { xs: 'none', md: 'flex' }, borderRadius: "40px"}}>
            Apply
          </Button>
          
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;