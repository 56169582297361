import React from "react";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { ref, get } from "firebase/database";
import { database } from "../components/formikInputs/firebaseConfig";
import "./MyApplication.css";

const MyApplication: React.FC = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = React.useState<User | null>(null);
  const [applicationSubmitted, setApplicationSubmitted] =
    React.useState<boolean>(false);
  const [hasSavedForm, setHasSavedForm] = React.useState<boolean>(false);
  const [interestFormSubmitted, setInterestFormSubmitted] = React.useState<boolean>(false);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const registrationsRef = ref(
          database,
          `registrations/${currentUser.uid}`
        );
        const interestFormRef = ref(database, `interestAEB/${currentUser.uid}`);

        try {
          const registrationsSnapshot = await get(registrationsRef);
          const interestFormSnapshot = await get(interestFormRef);
          let foundSubmitted = false;

          if (registrationsSnapshot.exists()) {
            registrationsSnapshot.forEach((childSnapshot) => {
              const applicationData = childSnapshot.val();
              if (applicationData.submittedAt) {
                foundSubmitted = true;
                setApplicationSubmitted(true);
                return true; // Exit loop
              }
            });

            if (!foundSubmitted) {
              const savedFormRef = ref(
                database,
                `registrations/${currentUser.uid}/savedForm`
              );
              const savedFormSnapshot = await get(savedFormRef);
              if (savedFormSnapshot.exists()) {
                setHasSavedForm(true);
              }
            }
          }

          if (interestFormSnapshot.exists()) {
            setInterestFormSubmitted(true);
          }
        } catch (error) {
          console.error("Error retrieving application data:", error);
        }
      }
    });
    return unsubscribe;
  }, [auth]);

  return (
    <Container className="home-container app-container">
      <div className="pigeon-container">
        <img
          src={`${process.env.PUBLIC_URL}/pigeon.png`}
          alt="Pigeon"
          className="pigeon-image"
        />
      </div>
      <Typography
        variant="h2"
        color="secondary.contrastText"
        sx={{ marginBottom: "2vh" }}
      >
        My Applications
      </Typography>
      <Typography
        className="text-reg font-nm-16"
        color="secondary.contrastText"
        sx={{ marginTop: "2vh", maxWidth: "60vw" }}
      >
        Get ready for an exciting multi-day hackathon this Spring! Join fellow
        hackers from around the world to connect, innovate, and grow. Stay tuned
        here for updates on your application status, and prepare to make
        unforgettable memories!
      </Typography>

      <Typography
        className="text-reg font-nm-16"
        color="secondary.contrastText"
        sx={{ marginTop: "2vh", maxWidth: "60vw", textDecoration: "underline" }}
      >
        Early Bird Application are CLOSED! General applications will open soon,
        please complete the interest form to be notified when they open.
      </Typography>

      {user ? (
        <>
          <TableContainer className="table-container">
            <Table className="table">
              <TableBody>
                <TableRow className="table-row">
                  <TableCell className="table-cell-bold">
                    <div className="table-cell-content">Interest Form</div>
                  </TableCell>
                  <TableCell className="table-cell">
                    <div className="table-cell-content">
                      {interestFormSubmitted ? (
                        "Completed"
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          sx={{ borderRadius: "40px", color: "white" }}
                          onClick={() => navigate("/interest-form")}
                        >
                          Apply
                        </Button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow className="table-row">
                  <TableCell className="table-cell-bold">
                    <div className="table-cell-content">Registration Form</div>
                  </TableCell>
                  <TableCell className="table-cell">
                    <div className="table-cell-content">
                      {applicationSubmitted ? (
                        "Under Review"
                      ) : (
                        <Typography
                          className="text-reg font-nm-16"
                          color="secondary.contrastText"
                        >
                          COMING SOON
                        </Typography>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography
          variant="body1"
          color="error"
          sx={{
            backgroundColor: "white",
            padding: "16px",
            borderRadius: "8px",
            marginTop: "5vh",
          }}
        >
          Please <a href="/login">Login/Signup</a> to view your application
          status.
        </Typography>
      )}
    </Container>
  );
};

export default MyApplication;

{/* <div className="table-cell-content">
  {applicationSubmitted ? (
    "Under Review"
  ) : hasSavedForm ? (
    <Button
      variant="contained"
      color="secondary"
      sx={{ borderRadius: "40px", color: "white" }}
      onClick={() => navigate("/registration")}
    >
      Continue
    </Button>
  ) : (
    <Button
      variant="contained"
      color="secondary"
      sx={{ borderRadius: "40px", color: "white" }}
      onClick={() => navigate("/registration")}
    >
      Apply
    </Button>
  )}
</div>; */}
