// Import the functions you need from the Firebase SDKs
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWK7_NcV8ZFJDvXTuhT23WhJI6wcR-SGQ",
  authDomain: "solutionhacksinterestform.firebaseapp.com",
  databaseURL: "https://solutionhacksinterestform-default-rtdb.firebaseio.com",
  projectId: "solutionhacksinterestform",
  storageBucket: "solutionhacksinterestform.firebasestorage.app",
  messagingSenderId: "295145890252",
  appId: "1:295145890252:web:17bd9e4c9fd75738073d69",
  measurementId: "G-0J8WJHQVF0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Realtime Database
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);

export { auth, database, storage };
